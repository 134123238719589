import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import me from "../images/me.webp";
import ziggy from "../images/ziggy.webp";
import Age from '../components/age';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" keywords={['Seraph', 'kumar', 'designer', 'user', 'experience', 'product', 'interaction', 'gatsby', 'application', 'react']} mdxType="SEO" />
    <img height="auto" loading="eager" src={me} alt="A stunning photograph of Seraph Kumar." className="center" />
    <h2>{`Design`}</h2>
    <p>{`I immigrated to the U.S. when I was 5. My first trip to an American grocery store was something else. I walked on a sidewalk, crossed a street when a traffic light told me to and I was greeted by an automatic door. These things made life nicer than what I was used to.`}</p>
    <p>{`It’s funny to think I became a designer cause I went to my local Vons. `}</p>
    <p>{`I’m addicted to improving the functionality of everything. If you get a chance to work with me, you’ll notice I say “efficient” too much.
It’s another way of saying "do more for less”.`}</p>
    <h2>{`Music`}</h2>
    <p>{`I've been making music since 2018. I find I'm naturally good at jazz but I prefer producing hip hop. It's much harder than you think.`}</p>
    <p>{`My comfort zones are Japanese city pop, David Bowie, and Playboi Carti. Here are some very old snippets of some songs I'm working on.`}</p>
    <iframe width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1266815197%3Fsecret_token%3Ds-MJO5qVZ1qzb&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{
      "fontSize": "10px",
      "color": "#cccccc",
      "lineBreak": "anywhere",
      "wordBreak": "normal",
      "overflow": "hidden",
      "whiteSpace": "nowrap",
      "textOverflow": "ellipsis",
      "fontFamily": "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
      "fontWeight": "100"
    }}><a href="https://soundcloud.com/user-240121693" title="Seraph" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>Seraph</a> · <a href="https://soundcloud.com/user-240121693/sets/snippets/s-MJO5qVZ1qzb" title="Snippets" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>Snippets</a></div>
    <h2>{`Ziggy`}</h2>
    <p>{`The most important thing going on in my life is my dog.
She's a `}<Age mdxType="Age" />{` year old German shepherd + rottweiler mix. Don't talk to me for too long, she WILL get jealous.`}</p>
    <img height="auto" loading="lazy" src={ziggy} alt="A stunning photograph of a baby." className="center" />
    <p>{`Guess who Ziggy is named after?`}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      